@mixin mq($size) {
    @media screen and (max-width:$size) {
        @content;
    }
}

#demo-mobile {
    @include mq(600px) {
        #demo-intro-header {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            .col-md-6{
                display: flex;
                flex-direction: column;
                .btn-start{
                    align-self: center;
                    margin-bottom: 5vh;
                }
                .gap-4{
                    padding-left: 0px;
                    &:last-child{
                        margin-bottom: 20px;
                    }
                }
                
            }
            h1,h4{
                max-width: 100%;
                margin: 0px;
            }
            video,.image{
                width: 100%;
                height: auto;
            }
        }
        #pitch{
            @extend #demo-intro-header;
            max-height: auto;
            min-height: auto;
            height: auto;
            margin-bottom: 40px;
            &:last-child{
                margin-bottom: 60px;
            }
            *{
                text-align: center;
            }
            .row{
                display: block;
                margin: 0px;
            }
            .image{
                margin-bottom: 20px;
            }
        }
        #demo-footer{
            @extend #demo-intro-header;
            max-height: auto;
            min-height: auto;
            height: auto;
            margin-top: 80px;
            * {
                text-align: center;
            }
            .row{
                display: grid;
                place-items: center;
                h1{
                    margin-top:30px;
                }
            }
        }
    }
}