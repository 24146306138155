@import "./Header.scss";

#demo-footer {
    @extend #demo-intro-header;
    min-height: 50vh;
    padding: 0rem 15rem;
    background-image: url("https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Group+943.png");
    background-position: center;
    background-size: cover;
    padding-top: 5vh;
    h1 {
        padding-top: 0px;
        font-weight: 400;
        margin-bottom: 1em;
    }

    h4 {
        font-size: 2.5rem;
    }

    .image-pitch {
        height: 50vh;
        width: auto;
    }
}