@mixin mqh($size) {
    @media screen and (max-height:$size) and (max-width:600px) {
        @content;
    }
}

#demo-process-page {
    @include mqh(815px) {
        #demo-process-result {
            position: fixed;
            min-height: 90vh;
            bottom: 0px;
            z-index: 2;

            .all-images {
                overflow-y: scroll;
                overflow-x: scroll;
                flex-wrap: wrap;
                max-height: 71vh;
                .singleImage {
                    min-width: calc(50% - 10px);
                    max-width: calc(50% - 10px);
                }
            }
        }
        #demo-video{
            min-height: calc(100vh - (6vh + 18px));
        }
        #demo-video .video .video-element{
            height: calc(100vh - (40vh + 18px));
        }
    }
}