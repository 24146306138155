@import "../../../sass/variables.scss";

#in-press {
    max-width: $max-width !important;

    @include mq($md) {
        max-width: 100% !important;
    }

    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
    margin-bottom: 30px;
    gap:20px;

    @include mq($lg) {
        padding: 0px 12px;
    }

    @include mq($xls) {
        grid-template-columns: 1fr;
        padding: 0px 12px;
        margin-top: 0px;
        margin-bottom: 60px;
    }

    .vogue {
        height: 150px;
        width: 100%;
        align-self: center;
        @include mq($md){
            height: 100px;
        }
    }

    .title-snap {
        width: 80%;
        height: auto;
        margin-bottom: 26px;
        margin-top: 4px;
        align-self: center;
    }

    .title-snap-1 {
        @extend .title-snap;
        margin-bottom: 0px;

        @include mq($md) {
            margin-top: 0px;
            width: 100%;
        }
    }

    .center {
        display: grid;
        place-items: center;
        width: 100%;
    }

    a{
        text-decoration: none;
    }

    .single-card {
        width: 100%;
        padding: 20px;
        background-color: #fff;
        border-radius: 35px;
        text-decoration: none;

        @include mq($xls) {
            min-width: (100%);
            max-width: (100%) !important;
        }
    }

    .vogue-card {
        @extend.single-card;
        height: 100%;
    }

    .flex {
        width: 100%;
    }

    .title {
        font-size: 21px;
        font-weight: 600;
        letter-spacing: -0.06em;
        color: #000;
    }

    .desc {
        font-size: 20px;
        color: rgba(0, 0, 0, 1);
        font-weight: 400;
        font-style: italic;
        @include mq($md){
            font-size: 16px;
            text-align: center;
        }
    }

    .logo {
        display: grid;
        place-items: center;
        margin-bottom: 16px;

        img {
            height: 32px;
            width: auto;
            max-width: 100%;
        }
    }

    .title-2 {
        @extend .title;
        display: grid;
        place-items: center;
    }

    .second-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        @include mq($md) {
            grid-template-columns: 1fr;
        }
    }
}

// ... additional styling as needed