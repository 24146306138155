@import "../../../sass/variables.scss";

.background {
    background-image: url("../../../../assets/background.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    min-height: calc(100vh - 0px);
    max-height: calc(100vh - 0px);
    min-width: 100vw;
    overflow: hidden;
    padding-top: 80px;
    @include mq($lg){
       padding-top: 60px;
    }
    @include mq($xls){
       padding-top: 40px;
    }
    video {
        width: 100vw;
        height: 100vh;
        object-fit: cover;

    }
}

#header-home {
    min-height: 100vh;
    display: flex;
    min-height: 100vh;
    max-height: 50vh;
    overflow: hidden;
    @include mq($xls) {
        padding-left: $mobile-padding;
    }

    .header-content {
        min-height: 100vh;
        display: grid;
        place-items: center;
        line-height: 1.1;
        @include mq($lg){
            padding-left: 15px;
        }
        @include mq($xls) {
            min-width: calc(100% - 9px);
            max-width: calc(100% - 9px);
            min-height: 100vh;
        }

        .text-size-4 {
            margin-bottom: 0.625vw;
            @include mq($xls){
                margin-bottom: 10px;
            }
        }

        p {
            max-width: 27.500vw;
            margin-bottom: 2.25vw;

            @include mq($xls) {
                max-width: 80%;
                margin-bottom: 20px;;
            }
        }

        .cta-btn {
            gap: 15px;
        }
    }
    .video-part{
        min-height: 100vh;
        display: grid;
        place-items: center;
        // background-image: url("../../../../assets/testapp.svg");
        background-position: center;
        background-size: 80%;
        background-repeat: no-repeat;
        @include mq($xls){
             min-height: 50vh;
        }
        video{
            width: 100%;
            height: auto;
            border-radius: 20px;
            border: solid 30px white;
            box-shadow: 0px 16px 12px 7px rgb(235 87 87 / 10%);
            @include mq($xls) {
                width: 95%;
                 border: solid 15px white;
            }
        }
    }

}