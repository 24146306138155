#demo-process-sidebar{
    min-width: 10%;
    max-width: 10%;
    min-height: 100vh;
    max-height: 100vh;
    background-color: #18191B;
    padding-top:8rem;
    .active{
        background-color: #252627;
    }
    .sidebar-icon{
        display: grid;
        place-items: center;
        min-height: 100px;
        transition: all 0.3s ease-out;
        &:hover{
            background-color: #252627;
        }
        .icons{
            display: grid;
            place-items: center;
        }
        img{
            width:30px;
            height:auto;
            margin-bottom: 3px;
        }
        .text{
            font-size: 1.5em;
            font-weight: 300;
            letter-spacing: 0.25px;
            text-align: center;
            color: #fff;
        }
    }
}
.fixed-sidebar{
    position: fixed;
    z-index: 100!important;
    bottom: 0;
}