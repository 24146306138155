@import "./Video.scss";

#pop-up-process {
    @extend #demo-video;
    position: absolute;
    min-width: 100vw !important;
    min-height: 100vh;
    z-index: 1007;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    padding-top: 10px;
    .btn-custom{
        cursor: pointer;
    }

    .payment-elements {
        #wrapper-div {
            border-radius: 0px;
            background: unset;
            padding: 0px;
        }
    }

    form {
        border-radius: 0px;
        max-width: auto;
        min-width: 50vw;
        width: auto;
        p{
            margin-bottom: 10px;
        }
    }

    .file-upload {
        max-width: 60%;

        svg {
            height: 20vh;
        }
    }

    .dropzone {
        text-align: center;
        padding: 20px;
        display: grid;
        place-items: center;
        border: 3px gray dashed;
        min-height: 20vh;
        margin: auto;
    }

    .process-panel {
        background-color: #f3f3f3;
        border-radius: 10px;
        padding: 30px;
    }

    .customer-product-info {
        background-color: #fff;
        border-radius: 10px;
        min-width: 400px;
        padding: 15px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;
    }

    .form-check {
        display: flex;
        place-items: center;
    }

    input[type="radio"] {
        height: 20px;
        width: 20px;
        margin-right: 15px;
        box-shadow: none;
        margin-left: 0.8vw;
        margin-bottom: 0.5vw;

        &:checked {
            background-color: black;
            border-color: black;
        }
    }

    .extension {
        padding: 10px 40px;
    }

    img {
        height: 30px;
        width: auto;
    }

    .text {
        font-size: 18px;
        letter-spacing: 0.25px;
        font-weight: 100;
        text-align: center;
        color: #000;
        line-height: 1;

        span {
            font-weight: 400;
        }
    }
    .cart-basket{
        img{
            margin-right: 10px;
        }
    }
    .garment-details .details {
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%),
        0 1px 2px rgb(0 0 0 / 24%);
    }

}