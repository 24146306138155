@import "./Header.scss";
#pitch {
    @extend #demo-intro-header;
    height: auto;
    min-height: unset;
    max-height: unset;
    padding: 0rem 5rem;
    h1{
        padding-top: 0px;
        font-weight: 400;
    }
    h4 {
        font-size: 2.5rem;
        margin-bottom: 0px;
    }
    .image {
        width:calc(100% - 30px);
        height:30vh;
        box-shadow: none !important;
    }
    .text-section,
    .img-section {
        display: grid;
        place-items: center;
    }
}
#protrait{
    .image{
        height: 50vh;
        width: auto;
    }
    margin-bottom:60px;
}