@import "./variables.scss";
@import "./mixins.scss";

@mixin mq($size) {
    @media screen and (max-width:$size) {
        @content;
    }
}
@mixin mqh($size) {
    @media screen and (max-height:$size) {
        @content;
    }
}



.MuiMenuItem-root {
    @include mq(600px){
        font-size: 12px !important;
    }
}



#demo-process-page {
    @include mq(600px) {
        flex-direction: column-reverse;
        display: flex;
        padding: 0px;
        background-color: white;

        #demo-process-sidebar {
            min-width: 100vw;
            max-width: 100vw;
            display: flex;
            min-height: 60px;
            max-height: 60px;
            height: 60px;
            border-radius: 15px 15px 0px 0px;
            background: #18191b;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            padding: 0px;
            margin: 0px;
            padding: 14px 20px;
            justify-content: space-between;
            z-index: 2;

            .sidebar-icon {
                min-height: 30px;
                filter: opacity(0.5);
            }

            .icons {
                .text {
                    display: none;
                }
            }

            .active {
                filter: opacity(1);
                background-color: #18191b;
            }


        }

        #demo-process-result {
            min-width: 100vw;
            max-width: 100vw;
            min-height: calc(30.69196428571429vh + 15px);
            border-radius: 15px 15px 0px 0px;
            background: #252627;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            transform: translateY(15px);
            padding: 15px 0px 30px 20px;
            margin: 0px;

            svg {
                max-height: 17vh;
            }

            h4 {
                font-weight: 400;
            }

            .all-images {
                flex-wrap: nowrap;
                max-width: calc(100vw);
                overflow-x: scroll;
                overflow-y: hidden;
                gap: 15px;
                padding-bottom: 15px;
                max-height: 26vh;
                @include mqh(896px){
                    max-height: 22vh;
                }
                .singleImage {
                    min-width: calc(40% - 10px);
                    max-width: calc(40% - 10px);

                    img,
                    video {
                        border-radius: 12px;
                        padding-bottom: 0px;
                        margin-bottom: 10px;
                    }

                    .text {
                        font-family: "Myriad Pro";
                        font-weight: normal;
                        font-size: 14px;
                        text-align: center;
                        text-transform: uppercase;
                        letter-spacing: 0.1em;
                        color: #fff;
                    }
                }

                .video {
                    min-width: calc(25% - 10px);
                    max-width: calc(25% - 10px);

                    .text {
                        font-size: 10px;
                    }
                }
            }
        }

        #demo-video {
            transform: translateY(15px);
            min-width: 100vw !important;
            max-width: 100vw !important;
            min-height: calc(100vh - calc(37vh + 18px));
            display: grid;
            place-items: center;
            .warning-text,
            #pop-up-process .warning-text{
                display: none;
            }
            .basket-text{
                display: none;
            }

            .cart {
                display: none !important;
            }

            .video {
                height: auto;
                padding: 30px;

                img,
                video {
                    height: auto;
                    width: calc(100vw - 112px);
                    border-radius: 19px;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                }

                .video-element {
                    height: calc(100vh - calc(50vh + 18px));
                    width: auto;
                }

                video {
                    height: calc(100vh - calc(52vh + 18px));
                    width: auto;
                }
            }

            .basket-header {
                position: fixed;
                top: 50px;

                img {
                    height: 35px;
                    width: auto;
                    margin-right: 0px;
                }

                .text {
                    font-weight: normal;
                    font-size: 14px;
                    text-align: left;
                    color: #18191b;
                }
            }
        }

        #pop-up-process {
            padding: 0px;

            .garment-details .details {
                min-width: 100%;
                max-width: 100%;
                border-radius: 20px;
            }

            .payment-elements {
                #wrapper-div {
                    border-radius: 20px;
                    background: #fff;
                    padding: 0px;
                    min-width: calc(100vw - 60px);
                    max-width: calc(100vw - 60px);

                    form {
                        min-width: 100%;
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }

            h2 {
                font-size: 2.5rem;
            }

            .upper-text {
                margin-bottom: 10px;
            }

            .process-panel {
                .payment-elements {
                    flex-direction: column-reverse;
                    form{
                        border-radius: 20px;
                    }

                    .empty-store {
                        width: 100%;
                    }

                    .customer-product-info {
                        min-width: calc(100vw - 100px);

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                    #payment-form {

                        span,
                        input,
                        button {
                            min-height: 30px;
                            height: 30px;
                            margin-bottom: 15px;
                        }

                        button {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
}
@import "./demoProcessSmallGeight.scss";
