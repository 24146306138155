@import "./Header.scss";
@import "../../../sass/variables.scss";

#app-test {
    @extend #header-home;
    display: grid;
    place-items: center;
    margin-bottom: 5vw;
    overflow: unset;
    overflow-y: unset;

    @include mq($lg) {
        padding-left: 15px;
    }

    @include mq($xls) {
        padding-top: 30px;
        margin-bottom: 30px;
        overflow: unset;
        overflow-y: unset;
        max-height: none;
    }

    .plugin {
        align-content: center;
        align-items: center;
        gap: 20px;

        @include mq($xls) {
            margin-bottom: 30px;
            margin-top: 20px;
        }

        img {
            @include mq($xls) {
                width: 45vw;
                height: auto;
            }
        }

        .shopify-r {
            color: black;
            font-family: $myriad-bold;
            font-weight: 900;
            text-transform: capitalize;
            font-style: italic;
            margin: 0px;
        }
    }

    .video-container {
        height: 90vh;
        width: (90vh * 0.4712084257206208);

        @include mq($xls) {
            height: 50vh;
            width: (50vh * 0.4712084257206208);
        }

        .iphone-image {
            position: absolute;
            // background-image: url("https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/iphoneframe.d3ea16c9sjkbdkb.png");
            background-position: center;
            background-size: cover;
            height: 90vh;
            width: (90vh * 0.4712084257206208);
            z-index: 10;

            @include mq($xls) {
                height: 50vh;
                width: (50vh * 0.4712084257206208);
            }
        }

        .video-wrapper {
            // overflow: hidden;
            width: calc(100% - 47px);
            height: calc(90vh - 7px);
            border-radius: 50px;
            transform: translateX(23px);

            @include mq($xls) {
                height: 50vh;
                border-radius: 35px;
                overflow: hidden;
                width: calc(100% - 0px);
                height: calc(90vh - 7px);
                border-radius: 20px;
                transform: translateX(10px);
            }

            @include mqh(850px) {
                border-radius: 20px;
                width: calc(100% - 21px);
                height: calc(90vh - 7px);
                transform: translateX(11px);
            }
        }

        video {
            width: auto;
            height: 100%;
            border-radius: 20px;
            transform: scale(0.96) translateX(-23px) translateY(3px);
            border:3px solid #212529;
            @include mq($xlg){
                @include mqh(700px){
                    transform: scale(0.98) translateX(-11px) translateY(6px);
                }
            }
            @include mq($xls) {
                height: 50vh;
                width: auto;
                transform: scale(0.95) translateX(-11px) translateY(0px);
                @include mqh(850px) {
                    transform: scale(0.95) translateX(-11px) translateY(0px);
                }
            }

        }
    }


    .row {
        @include mq($xls) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .col-md-6 {
        display: grid;
        min-height: 100vh;
        align-items: center;
        align-content: center;
        justify-items: start;

        @include mq($xls) {
            min-height: auto;
        }

        &:first-child {
            justify-items: flex-end;
            justify-content: flex-end;

            @include mq($xls) {
                justify-items: center;
                justify-content: center;
                margin-bottom: 20px;
            }
        }

        p,
        .text-size-4 {
            max-width: calc(100% - 54px);
        }

        p {
            max-width: calc(100% - 20px);
        }
    }

    .text-size-3 {
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-family: $myriad-regular;
        margin-bottom: 0.500vw;
    }

    .text-size-4 {
        margin-bottom: 1vw;

        @include mq($xls) {
            margin-bottom: 15px;
        }
    }

    .images {
        background-image: url("../../../../assets/backgorundlorem.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .text-section {
        padding-left: 5vw;

        @include mq($xls) {
            padding-left: 0px;
            min-width: 100vw;
            padding-left: 10px;
        }

        ;
    }
}