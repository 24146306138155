@import "./Sidebar.scss";

#demo-process-result {
    @extend #demo-process-sidebar;
    min-width: 40%;
    max-width: 40%;
    background-color: #252627;
    padding: 2.063rem;
    padding-top: 9rem;
    overflow: scroll;

    .sketch-picker {
        background-color: #202124 !important;

        label {
            color: white !important;
        }
    }

    h4 {
        font-size: 3rem;
        margin-bottom: 1rem;
        font-family: "myriad";
        font-weight: 300;
        margin-top: 0;
        color: white;
    }

    .header {
        min-width: 100%;
        justify-content: space-between;

        img {
            height: 30px;
            width: auto;
        }
    }

    .all-images {
        gap: 1.25rem;
        flex-wrap: wrap;
    }

    .singleImage {
        max-width: calc((100%/3) - 10px);
        min-width: calc((100%/3) - 10px);

        img,video {
            max-width: 100%;
            height: auto;
            padding-bottom: 1.25rem;
        }

        .text {
            font-size: 1.5em;
            font-weight: 300;
            letter-spacing: 0.25px;
            text-align: center;
            color: #fff;
        }
    }
    .singleImageSelected{
        @extend .singleImage;
        padding:6px;
        border-radius: 6px;
        background: darken($color: #0E4DA4, $amount: 15);
    }
    .empty-text{
        @extend h4;
        font-size: 2.5rem;
        text-align: center;
    }
    .lottie-file{
        min-width: calc(100% - 20px);
        max-width: calc(100% - 20px);
    }
}