@import "../../sass/variables.scss";
@import "../../sass/functions.scss";

#intergration {
    padding: 0px 0vw;
    padding-bottom: divide(142);
    max-width: 1300px;
    min-width: 100%;

    @include mq($xls) {
        * {
            max-width: calc(100vw - 30px);
        }
    }

    h1 {
        margin-bottom: pxToVh(53);

        @include mq($xls) {
            margin-bottom: pxToVh(25);
        }
    }

    .row {
        width: 100%;
        min-width: 100%;
    }

    h3 {
        font-size: 3.125em;
        margin-bottom: divide(30);
        max-width: 100%;
        @include mq($xls) {
            max-width: 100%;
            font-size: (3.125em+.5);
        }
    }

    h6 {
        font-size: 1.5625em;
        margin-bottom: divide(70);
        max-width: 83%;

        @include mq($xls) {
            max-width: 100%;
            font-size: (1.5625em+.5);
        }
    }

    .icon-group-container {
        background-color: #11243e;
        border-radius: 20px;
        padding: divide(30);

    }

    .h5 {
        font-size: 2.5em;
        margin-top: divide(30);
        margin-bottom: divide(22);
        padding-left: divide(20);
        border-left: solid 3px #eb5757;
        @include mq($xls) {
            max-width: 100%;
            font-size: (2.5em+.5);
        }
    }

    .h6 {
        @extend h6;
        max-width: 20vw;
        line-height: 1.3;
        color: #a0a4a8;
        margin-bottom: 0px;
        padding-left: divide(20);

        @include mq($xls) {
            max-width: 100%;
            margin-bottom: 20px;
        }
    }

    img {
        width: divide(90);
        height: auto;
        margin-left: divide(20);
    }

    .icon-wrapper {
        gap: 20px;
    }

    .video-part {
        padding-left: divide(48);
        display: grid;
        place-items: center;
        align-items: center;
        align-content: center;
        @include mq($xls){
            padding-left: divide(0);
            margin-top: divide(30);
        }
    }

    .video-wrapper {
        video {
            min-width: 100%;
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }
    }

    .text-title {
        font-size: 4.6875em;
        color: #73fbfd;
        line-height: 1;
    }

    .last-icon {
        .h6 {
            max-width: 90%;
        }
    }
}