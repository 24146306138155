@import "./analytics.scss";

#inventory {
    @extend #analytics;
    margin-bottom: 5vw;

    .top-text {
        margin-bottom: pxToVh(68);
        @include mq($xls){
            margin-bottom: 30px;
        }
    }

    .images-container {
        gap: pxToVw(60);
        align-items: center;
        align-content: center;
        @include mq($xls){
            flex-direction: column;
            padding: 15px;
        }
        .col-md-4 {
            display: grid;
            place-items: center;
        }

        video {
            width: 100%;
            height: auto;
            border-radius: 20px;
        }
        .left,.right{
            width: 35%;
            @include mq($lg){
                width:40%;
            };
            @include mq($xls){
                width: 100%;
            }
        }
        .right{
            @include mq($xls){
                margin-bottom: 30px;
            }
        }
        .center{
            width: 40%;

            @include mq($lg) {
                width: 45%;
            }

            @include mq($xls) {
                width: 100%;
            }
        }
        .fullHeight{
            width: 40%;

            @include mq($lg) {
                width: 45%;
            }

            @include mq($xls) {
                width: 100%;
            }
        }
        .baseHeight{
            width: 35%;

            @include mq($lg) {
                width: 40%;
            }

            ;

            @include mq($xls) {
                width: 100%;
            }
        }
    }
}