@mixin mqh($size) {
    @media screen and (max-height:$size) {
        @content;
    }
}
@import "../../sass/variables.scss";
#demo-intro-header {
    background-color: black;
    min-height: 100vh;
    padding: 12rem 5rem;
    color: white;
    display: grid;
    place-items: center;
    max-width: 1300px;
    @include mqh(768px){
         padding: 5rem 5rem;
    }
    @include mq($xls){
        padding-top: 20px;
    }
    h1 {
        font-size: 4.5rem;
        font-family: "myriad";
        font-weight: 600;
        padding-top: 6rem;
        margin-bottom: 16px;
    }

    h4 {
        font-size: 3rem;
        margin-bottom: 2rem;
        font-family: "myriad";
        font-weight: 300;
        margin-top: 0;
    }

    .btn-start {
        background-color: #6FCF97;
        color: #fff;
        border: none;
        margin: 2rem 2rem 0 0;
        height:55px;
        display: grid;
        place-items: center;
        width: 21.5rem;
        border-radius: 500px;
        font-size: 2rem;
        cursor: pointer;
        font-family: "myriad";
        font-weight: 400;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        margin-bottom:10vh;

        p {
            font-weight: 900;
            line-height: 1;
            margin-bottom: 15px;
            color:white;
        }

        h6 {
            font-size: 1rem;
            line-height: 1;
        }
        @include mq($xls){
            min-width: 50vw;
            div{
                min-width: 40vw;
                display: grid;
                place-items: center;
            }
        }
    }

    .d-flex {
        padding-left: 3.313rem;

        img {
            height: 30px;
            width: auto;
        }

        div {
            align-self: center;
            font-size: 2rem;
            margin-bottom: 2rem;
            font-family: "myriad";
            font-weight: 300;
            margin-top: 0;
        }
    }
    video{
        height:80vh;
        width:auto;
        box-shadow: 0 0 30px 10px rgba(255, 255, 255, 0.2);
        @include mqh(768px) {
            margin-top: 50px;
        }
    }
    .pitch-icon{
        .gap-4{
            margin-bottom: 1vh;
        }
    }
}