@import "./intergration.scss";

#solutions {
    @extend #intergration;
    background: white;
    color: black;
    max-width: 1300px;
    min-width: 100vw;

    h1 {
        padding-top: divide(94);
        padding-bottom: divide(68);
        margin: 0px;

        @include mq($xls) {
            text-align: center;
        }
    }

    .solution-item {
        background: linear-gradient(179.97deg, #EFF8F3 -0.02%, #FFFFFF 94.17%);
        border-radius: 40px;
        min-width: calc(38vw - 20px);
        padding: divide(60);
        @include mq($xxlg) {
          min-width: calc(24vw - 20px);
          max-width: calc(24vw - 20px);
        }
        @include mq($lgmd) {
          min-width: calc(34vw - 20px);
          max-width: calc(34vw - 20px);
        }
        @include mq($xlg) {
            min-width: calc(43vw - 20px);
            max-width: calc(43vw - 20px);
        }
        @include mq($md) {
            padding: 20px;
        }

        @include mq($xls) {
            min-width: 100%;
            max-width: 100%;
            padding: 20px;
        }
    }

    .top-text {
        align-content: center;
        align-items: center;
        gap: divide(30);
        margin-bottom: divide(20);
        padding-left: divide(60);

        @include mq($md) {
            padding-left: divide(0);
        }

        h3 {
            line-height: 1;
            margin: 0px;
            color: black;
        }
    }

    .h6 {
        color: black;
        max-width: 100%;
        margin-bottom: divide(61);
    }

    .solution-video-wrapper {
        display: grid;
        place-items: center;

        video {
            width: 50%;
            height: auto;
            border-radius: 32px;
            padding-right: 10px;
            padding-left: 10px;
            transform: translateY(4px);
            @include mq($xlg) {
                border-radius: 22px;
                padding-right: 10px;
                padding-left: 10px;
                transform: translateY(4px);
            }
            @include mq($lg){
                border-radius: 22px;
                padding-right: 10px;
                padding-left: 10px;
                transform: translateY(4px);
            }
            @include mq($md) {
                width: 100%;
                height: auto;
                border-radius: 47px;
                padding-right: 10px;
            }

            @include mq($xls) {
                width: 100%;
                height: auto;
                border-radius: 66px;
                padding-right: 10px;
            }
        }
    }

    .second-item {
        background: linear-gradient(180.18deg, #FDF5DD 0.44%, #FFFFFF 100.13%);
        border-radius: 40px;
    }

    .all-solutions {
        justify-content: center;
        gap: 20px;
        padding: 0px 20px;

        @include mq($xls) {
            width: 100%;
            display: grid;
            place-items: center;
            min-width: 100vw;
            display: grid;
            place-items: center;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            padding: 0px 17px;
        }
    }

    .btn-primary {
        min-width: 15vw;

        @include mq($xls) {
            min-width: 50vw;
        }
    }
    .iphone-frame{
        background-image: url("https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/iphoneabout.png");
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        position: absolute;
        margin: 0px;
        padding: 0px;
        transform: translateY(4px) translateX(-2px);
        z-index: 10;
    }
}