@import "./Sidebar.scss";
@mixin mqh($size) {
    @media screen and (max-height:$size) {
        @content;
    }
}
#demo-video {
    @extend #demo-process-sidebar;
    min-width: 50% !important;
    max-width: 50% !important;
    background-color: #EBECF0;
    display: grid;
    align-content: space-between;
    .empty-store{
        background-color: white;
        padding:20px;
        div{
            display: grid;
            place-items: center;
            .empty-text{
                font-size:13px;
            }
        }
    }
    .btn-custom {
        cursor: pointer;
    }
    .warning-text{
        width:100%;
        background-color: lightgray;
        display: grid;
        place-items: center;
        justify-content: center;
        margin-bottom: 10px;
        padding:10px 2.5rem;
        font-size: 1.5rem;
        div{
            max-width: 82%;
            text-align: center;
        }
    }

    .video {
        width: 100%;
        height: calc(100vh - 25rem);
        display: grid;
        place-items: center;
    }

    video,img {
        height: 70vh;
        width: auto;
    }
    video{
        @include mqh(768px){
            width: 35%;
            height: auto;
        }
        @include mqh(690px) {
            width: 25%;
            height: auto;
        }
        @include mqh(500px) {
            width: 21%;
            height: auto;
        }
    }
    img{
        width: 50%;
        height: auto;
        @include mqh(600px) {
            width: 30%;
            height: auto;
        }
    }

    .cart-basket {
        align-items: center;
    }

    .cart {
        height: 7.625rem;
        width: 100%;
        background-color: #fff;
        padding: 0rem 2.4rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        align-content: center;
        align-items: center;

        .text {
            font-size: 18px;
            letter-spacing: 0.25px;
            font-weight: 100;
            text-align: center;
            color: #000;
            line-height: 1;

            span {
                font-weight: 600;
            }
        }

        .btn-cart {
            max-height: 40px;
            min-height: 40px;
            min-width: 150px;
            background-color: #0e4da4;
            font-size: 1.5rem;
            text-align: center;
            color: #fff;
            display: grid;
            place-items: center;
            align-items: center;
            align-content: center;
            .d-flex {
                gap: 10px;
                align-items: center;
                justify-content: center;
                place-items: center;
                div{
                    line-height: 1;
                }
            }

            img {
                height: 25px;
                width: auto;
            }
        }
    }

    .basket-top {
        position: absolute;
        left: 0;
        min-width: 100%;
        display: grid;
        place-items: center;
        pointer-events: none;
    }

    .basket-header {
        width: 100%;
        @extend .cart;
        background-color: rgba($color: #000000, $alpha: 0.0);
        box-shadow: none;
        max-width: 1300px;
        place-items: unset;
        max-height: 45px;
        padding-right: 2rem;
        img {
            height: 30px;
            width: auto;
            margin-right: 7px;
        }
    }

    .basket-all-details {
        max-width: 1300px;
        ;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0px;
        pointer-events: none;
    }

    .garment-details {
        z-index: 100;
        pointer-events: all;

        .close-btn {
            height: 60px;
            width: 30px;
            background-color: white;
            display: grid;
            place-items: center;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

            img {
                height: 30px;
                width: auto;
                margin: 0px;
            }
        }

        .details {
            min-width: 30rem;
            background-color: white;
            padding: 20px;

            .section-text {
                font-weight: 400;
            }

            .product-text {
                padding-left: 20px;
            }

            .single-section {
                margin: 5px 0px;
            }

            .clear {
                .text {
                    color: #0E4DA4;
                    font-weight: 400;
                }
            }

            .btn-digitize {
                width: 100%;
                height: 60px;
                background-color: #6fcf97;
                border-radius: 5px;
                display: grid;
                place-items: center;
                margin-top: 2.5rem;

                .text {
                    color: white;
                    line-height: 1.1;
                }
            }
        }
    }
}