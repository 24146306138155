@import "../../sass/variables.scss";
@import "../../sass/functions.scss";

#aboutv2 {
    min-height: 100vh;
    min-width: 100vw;
    background-color: #11152C;
    padding-top: 120px;
    color: white;
    display: grid;
    justify-content: center;
    place-items: center;
    @include mq($xls){
        padding-top: 0px;
        display: block;
        padding-top: 50px;
    }
    .element-wrapper {
        max-width: 1300px;
        display: grid;
        place-items: center;
        align-items: center;
        justify-items: center;
        justify-content: center;
        padding:15px;
        @include mq($xls){
            padding: 9px;
            // max-width: 50vw;
        }
    }

    * {
        font-family: $myriad-regular;
    }
}