@import "./components/sass/fonts.scss";
@import "./components/sass/variables.scss";

.container-custom {
    font-family: $myriad-light;
    display: grid;
    place-items: center;
    justify-content: center;
    width: 100%;

    &:first-child {
        max-width: 1187px !important;
    }
}

#analytics,
#app-test,
#header-home,
#inventory,
#ourimpact,
#universities {
    max-width: 1187px !important;

    @include mq($xls) {
        max-width: 100vw;
    }
}

.text-size-1 {
    font-size: 0.813vw;
    font-family: $myriad-light;
}

.text-size-2 {
    font-size: 0.938vw;
    font-family: $myriad-light;
}

.text-size-3 {
    font-size: 1.500vw;
    font-family: $myriad-light;

    @include mq($xls) {
        font-size: 16px;
    }
}

.text-size-4 {
    font-size: 3.5vw;
    font-family: $myriad-light;
    line-height: 1.1;

    @include mq($xls) {
        font-size: 40px;
    }
}

b {
    font-family: $myriad-bold;
    font-weight: bolder;
}

p {
    @extend .text-size-2;
    line-height: 1.2;
    color: $gray;
    max-width: 100%;

    @include mq($xls) {
        max-width: calc(100% - 20px);
    }
}


.btn {
    min-height: 45px;
    min-width: 10.375vw;
    max-width: 10.375vw;
    width: auto;
    border-radius: 100px;
    display: grid;
    place-items: center;
    font-size: 2rem;
    line-height: 1;
    color: $black;
    font-family: $myriad-light;
    font-weight: bold;

    @include mq($lg) {
        font-size: 16px;
        min-height: 40px;
        min-width: 20vw;
        max-width: 20vw;
        font-family: $myriad-light;
        font-weight: lighter;
    }

    @include mq($xls) {
        font-size: 16px;
        min-height: 40px;
        min-width: 43vw;
        max-width: 43vw;
    }
}

.btn-primary {
    background-color: $blue;
    color: $white !important;
    border: none;

    &:hover {
        background-color: lighten($color: $blue, $amount: 6);
    }
}

.btn-default {
    border: solid 1px $black;
}

.btn-danger {
    background-color: $red;
    color: $white !important;
    border: none;

    &:hover {
        background-color: lighten($color: $red, $amount: 6);
    }
}

body {
    background-color: #F4F9FF;

    @font-face {
        font-family: myriad;
        src: url("./fonts/MyriadPro-Light.otf");
    }

    #demo-mobile,
    #demo-process-page {
        * {
            font-family: myriad !important;
        }
    }

    max-width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
}