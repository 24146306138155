@font-face {
    font-family: myriad-light;
    src: url("../../assets/fonts/MyriadPro-Light.otf");
}
@font-face {
    font-family: myriad-bold;
    src: url("../../assets/fonts/MYRIADPRO-BOLD.OTF");
}
@font-face {
    font-family: myriad-bold-condo;
    src: url("../../assets/fonts/MYRIADPRO-BOLDCOND.OTF");
}
@font-face {
    font-family: myriad-regular;
    src: url("../../assets/fonts/MYRIADPRO-REGULAR.OTF");
}
@font-face {
    font-family: myriad-semi-bold;
    src: url("../../assets/fonts/MYRIADPRO-SEMIBOLD.OTF")
}
