@import "../../components/sass/variables.scss";
@import "../../components/sass/functions.scss";

.line-container {
    min-width: 100vw;
    display: flex;
    justify-content: space-between;
    background-color: #181820;
    .line1,
    .line2,
    .line3,
    .line4 {
        min-width: calc((100vw/4) - 5px);
        max-width: calc((100vw/4) - 5px);
        min-height: 4px;
    }

    .line1 {
        background-color: #0E4DA4;
    }
    .line2 {
        background-color: #6FCF97;
    }
    .line3 {
        background-color: #F2C94B;
    }
    .line4 {
        background-color: #EB5757;
    }
}

#footerv2 {
    .line {
        svg {
            width: 100vw;
        }
    }

    .footer-content {
        min-height: 45vh;
        width: 100%;
        background-color: #181820;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .footer-logo {
            min-height: 15vh;
            min-width: 100%;
            display: grid;
            place-items: center;
        }

        .sections-container {
            min-width: 100vw;
            justify-content: center;
            padding: 0 7.875vw;
            gap:20px;

            @include mq($xls) {
                padding-left: 15px;
                padding-right: 15px;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        .footer-section {
            color: $white;
            font-size: 1.2vw;
            font-family: $myriad-light;

            @include mq($xls) {
                min-width: 45vw;
                margin-bottom: 30px;
            }

            .footer-title {
                font-size: 1.500vw;
                font-family: $myriad-light;
                line-height: 1;

                @include mq($md) {
                    font-size: 25px;
                }

                .line {
                    min-width: 40px;
                    max-width: 40px;
                    min-height: 3px;
                    background-color: #FEFFFF;
                    border-radius: 200px;
                    margin-top: pxToVh(10);
                    margin-bottom: pxToVh(20);
                }
            }
        }

        .footer-text-size {
            margin-bottom: 5px;
            color: $white;
            font-size: 0.938vw;
            font-family: $myriad-light;

            @include mq($lg) {
                font-size: 16px;
            }
        }

        .copyright {
            @extend .sections-container;

            hr {
                color: white;
                opacity: 1;
                margin-bottom: 30px;
            }

            .social-media {
                display: flex;
                gap: 30px;

                img {
                    height: 20px;
                    width: auto;
                }
            }
        }
    }
}