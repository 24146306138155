@import "./OurImpact.scss";
#analytics{
    @extend #ourimpact;
    .impact-text{
        text-align: center;
    }
    .impact-cards{
        padding-top: pxToVh(50);
        display: grid;
        .row {
            width: 100%;
            .text-part{
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: end;
                justify-items: end;
                padding-right: pxToVw(100);
                @include mq($xls){
                    justify-content: start;
                    justify-items: start;
                }
                .wrapper {
                    width:64%;
                    @include mq($xls){
                        width:calc(100% - 30px);
                    }
                }
            }
            .number-percentage{
                margin-bottom: pxToVh(20);
            }
        }
    }
    .col-md-6{
        .text-right{
            text-align: right;
            @include mq($xls){
                text-align: left;
            }
        }
    }
    img{
        @include mq($lg){
            width: 100%;
            height: auto;
        }
        @include mq($xls){
            width:calc(100vw - 28px)
        }
    }
}