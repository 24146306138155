@import "../../../sass/variables.scss";
#news-home-page{
    display: grid;
    place-items: center;
    .carousel-root{
        width: 100vw;
    }
    .carousel.carousel-slider{
        overflow: unset;
        // overflow-x:hidden;
    }
    .carousel .control-dots{
        @include mq($xls){
            bottom: -50px;
        }
    }
    .carousel .control-dots .dot.selected{
        width: 15px;
        height: 15px;
        background-color: #0e4da4;
    }
    .carousel .control-dots .dot{
        width: 15px;
        height: 15px;
        background-color: #fff;
        border: 2px solid #0e4da4;
    }
    .header-container{
        margin-bottom: 4vw;
        margin-top: 4vw;
        @include mq($xls){
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }
    .single-news-column{
        max-width: 1285px !important;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap:12px;
        padding-bottom:calc(1vw + 40px);
        @include mq($lg){
            max-width: calc(100% - 30px) !important;
        }
        @include mq($md){
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include mq($xls){
            display: grid;
            grid-template-columns: 1fr;
            margin-bottom: 30px;
        }
        
    }
    .single-news-element{
            width: 100%;
            padding:20px;
            background-color: #fff;
            border-radius: 35px;
            display:grid;
            align-content: space-between;
            @include mq($xls){
                min-width:(100%);
                max-width:(100%) !important;
            }
            .image-back{
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                min-width: 100%;
                min-height: 300px;
                border-radius: 18px;
                margin-bottom: 15px;
            }
            video{
                min-width: 100%;
                max-width: 100%;
                min-height: 300px;
                height: auto;
                border-radius: 18px;
                margin-bottom: 15px;
                background-color: #000;
                padding:10px;
            }
            .header{
                font-size: 1vw;
                text-align: center;
                font-weight: 900;
                color: #000;
                margin-bottom: 15px;
                @include mq($xlg){
                    font-size: 1.5vw;
                }
                @include mq($xls){
                    font-size: 22px;
                }
            }
            .small-text{
                font-size: 25px;
                font-weight: 300;
                text-align: center;
                color: #000;
            }
            p {
                font-size: 15px;
            }
            .no-content{
                height:0px;
                overflow-y: hidden;
                opacity: 0;
                transition: all 0.5s ease-out;
            }
            .artcile-content{
                margin-bottom: 20px;
                height:50px;
                // overflow-y: unset;
                opacity: 1;
            }
            .expand-text{
                cursor: pointer;
                p{
                    font-size: 14px;
                }
                display: grid;
                place-items: center;
                .expand-icon{
                    width: auto;
                    height: 7.5px;
                    margin: 0px;
                    padding: 0px;
                    transition: all 0.3s ease-out;
                }
                .expand-icon-rotate{
                    @extend .expand-icon;
                    transform: rotate(180deg);
                }
            }
        }

    .carousel-container{
        display: none;
        @include mq($xls){
            display: block;
        }
    }
    .desktop-container{
        display: block;
        @include mq($xls){
            display: none;
        }
    }
}