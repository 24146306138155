@import "./OurImpact.scss";

#home-page {
    @include mq($xls) {
        max-width: 100vw;
    }

    .news-section {
        margin-top: 50px;

        @include mq($xls) {
            margin-bottom: 30px;
        }
    }
}