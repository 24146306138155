$black:#333333;
$red:#EB5757;
$white:#FEFFFF;
$blue:#0E4DA4;
$gray:#666666;
$headerPadding:66px;
$footerblack:#181820;
$myriad-light:myriad-light;
$myriad-bold:myriad-bold;
$myriad-bold-condo:myriad-bold-condo;
$myriad-regular:myriad-regular;
$myriad-semi-bold:myriad-semi-bold;
$max-width:1300px;
$sm: 480px;
$small-device:500px;
$xxs:320px;
$xls:600px;
$xlsh:500px;
$xms:375px;
$md:780px;
$lg:1024px;
$xlg:1440px;
$xxlg:5000px;
$lgmd:1920px;
$headervh:100vh;
$header-padding:10vw;
$mobile-padding:9px;
$divider:1/1.5;
@mixin mq($size) {
    @media screen and (max-width: $size) {
        @content;
    }
}

@mixin mqh($size) {
    @media screen and (max-height: $size) {
        @content;
    }
}
$max-width:1285px;
