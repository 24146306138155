@import "../sass/variables.scss";

#navbar {
    max-width: 100vw;
    padding-top: 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    padding-bottom: 20px;
    background-color: $white;
    @include mq($lg){
        padding-top: 12px;
        padding-bottom: 12px;
    }
    @include mq($xls) {
        min-width: 100vh;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .burger-menu {
        display: none;
        position: absolute;

        @include mq($xls) {
            display: block;
            position: fixed;
            left: 90vw;
        }
    }
    

    .container {
        display: flex;
        max-width: $max-width;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        * {
            font-size: 2rem;
            line-height: 1;
            color: $black;
            font-weight: lighter;
            transition: all 0.3s ease-out;
            @include mq($lg){
                font-size: 16px;
                font-family: $myriad-light;
                font-weight: 100;
            }
            @include mq($xls) {
                font-size: 16px;
                font-family: $myriad-light;
                font-weight: 100;
                margin-bottom: 0px;
                color:$white;
            }
        }

        .links {
            display: flex;
            gap: 4vw;

            @include mq($xls) {
                display: none;
            }

            
        }


        .cta {
            display: flex;
            gap: 20px;

            div {
                min-height: 38px;
                min-width: 10.375vw;
                width: auto;
                border-radius: 100px;
                display: grid;
                place-items: center;
                @include mq($lg){
                    min-width: 15vw;
                }
                @include mq($xls){
                    min-width: 60vw;
                }
            }

            .talk-to-sales {
                border: solid 1px $black;
                font-weight: bold;
                @include mq($xls){
                    border-color: $white;
                }
            }

            .try-for-free {
                background-color: $red;
                color: $white;

                &:hover {
                    background-color: lighten($color: $red, $amount: 6);
                }
            }

            @include mq($xls) {
                display: none;
            }
        }
    }
    .link-item {
        @include mq($lg) {
            color: black;
            font-size: 16px;
            font-family: $myriad-light;
            font-weight: 100;
            margin-bottom: 10px;
            .line{
                display: none;
            }
        }
        @include mq($xls){
            color: white;
        }
        button{
            height: auto !important;
            padding: 0px !important;
            background-color: rgba($color: #000000, $alpha: 0.0) !important;
            box-shadow: none !important;
        }
        .line {
            width: 0px;
            transform: translateY(0px);
            transition-duration: 0.6s;
        }
        li{
            padding: 15px;
        }

        &:hover {
            .line {
                width: 100%;
                transform: translateY(10px);
                height: 3px;
                background-color: $blue;
            }
        }
    }
    .links-container{
        margin-bottom: 30px;
    }
    .talk-to-sales {
        @include mq($xls) {
            border-color: $white;
            margin-bottom: 15px;
        }
    }
    .logo {
        margin-bottom: 0px;
        img {
            height: 41px;
            width: auto;

            @include mq($lg) {
                height: 24px;
                width: auto;
                margin-bottom: 0px;
            }
        }
    }
}