@import "../components/sass/variables.scss";
#wrapper-div{
    display: flex;
    justify-content: space-between;
    gap:5vw;
    max-width: none;
    padding-left: 17vw;
    padding-right: 17vw;
    @include mq($xlg) {
        padding-left: 2vw;
        padding-right: 2vw;
    }
    @include mq($md){
        width: calc(100% - 0px);
    }
    .info{
        min-width: 40%;
        max-width: 40%;
        @include mq($md) {
            min-width: 100%;
            max-width: 100%;
        }
    }
    form{
        min-width:50%;
        max-width:100%;
    }
    p{
        margin-bottom: 10px;
    }
}