@import "../../../sass/functions.scss";
@import "../../../sass/variables.scss";
#ourimpact{
    // margin-bottom: 5vw;
    @include mq($lg) {
        padding-left: 15px;
        padding-right: 15px;
    }
    .left-text{
        padding-top: 15px;
        @include mq($xls){
            padding-bottom: 0px;
        }
        p{
            margin-bottom: 2.125vw;
            @include mq($xls) {
                margin-bottom: 0px;
            }
        }
    }
    .row{
        @include mq($xls) {
            padding-left: 10px;
        }
    }
    .col-md-6{
        &:first-child{
            .impact-text{
                display: flex;
                justify-content: start;
                padding-right: 0px;
                @include mq($xls){
                    padding-right: 0px;
                    justify-content: start;
                }
            }
        }
    }
    .impact-cards{
        padding: 0px 0vw;
        display: flex;
        gap:30px;
        justify-content: space-between;
        padding-top: pxToVh(60);
        padding-bottom: pxToVh(120);
        flex-wrap: wrap;
        @include mq($xls){
            padding-left: 10px;
            padding-right: 0px;
            padding-top: 30px;
            padding-bottom: 60px;
            justify-content: start;
        }
        .number-percentage {
            font-family: $myriad-bold;
            font-size: 4em;
            line-height: 1;
            margin-bottom: pxToVh(10);
        }

        .number-desc {
            font-size: 1.5em;
            color: #7E858E;
            line-height: 1;
            @include mq($xls){
                font-size: 2rem;
                line-height: 1.3;
            }
        }
        .single-card{
            min-width: 30%;
            max-width: 30%;
            padding:37px;
            background-color: $white;
            border-radius: 20px;
            box-shadow: 0px 16px 12px 7px rgb(242 201 75 / 10%);
            @include mq($xls){
                min-width: calc(100% - 25px);
                max-width: calc(100% - 25px);
            }
            .d-flex{
                gap:20px;
                img{
                    height: pxToVw(100);
                    @include mq($xls){
                        height: 60px;
                    }
                }
            }
        }
    }
}