@import "../aboutv2/styles/intergration.scss";

#faq {
    @extend #intergration;
    padding-top: divide(150);
    background-color: #11152C;
    min-height: 100vh;
    max-width: 1300px;
    @include mq($xls){
        padding-top: 70px;
    }
    * {
        color: white;
    }

    .heading {
        display: grid;
        place-items: center;

        h3 {
            font-size: 4.375em;
            margin: 0px;
            padding: 0px;
            line-height: 1.3;
        }

        h6 {
            max-width: 100%;
            font-family: $myriad-light;
            font-size: 3.125em;
        }


    }
     h3 {
         font-size: 4.375em;
         margin: 0px;
         padding: 0px;
     }
    video {
        min-width: 90%;
        width: 90%;
        height: auto;
        border-radius: 20px;
        @include mq($xls){
              min-width: 100%;
              width: 100%;
        }
    }
    .faq-header {
        padding-top: divide(117);
        padding-bottom: divide(82);
        @include mq($xls){
            padding-top: divide(50);
            padding-bottom: divide(50);
        }
    }
    h6 {
        font-family: $myriad-light;
        line-height: 1.3;
        @include mq($xls){
            margin-bottom: 30px;
        }
    }
    h4{
        font-size: 3.125em;
        padding-bottom: divide(30);
        @include mq($xls){
            margin-bottom: -5px;
        }
    }
    .all-faq{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mq(1300px){
            padding: 0px 20px;
        }
    }
    .single-faq{
        max-width: calc(50% - 20px);
        min-width: calc(50% - 20px);
        @include mq($xls){
            max-width: 100%;
            min-width: 100%;
        }
    }
    .btn-primary {
        min-width: 15vw;

        @include mq($xls) {
            min-width: 50vw;
        }
    }
}

.faq-main {
    display: grid;
    place-items: center;
    justify-content: center;
    justify-items: center;
    min-width: 100vw;
    background-color: #11152C;
}