@import "./inventory.scss";
#cta{
    min-height: 50vh;
    width: 100%;
    background-image: url("../../../../assets/onlineMarketing.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: grid;
    place-items: center;
    .wrapper{
        display: grid;
        place-items: center;
    }
    .text-element{
        text-align: center;
        margin-bottom: pxToVh(50);
    }
    .ctas{
        gap:pxToVw(20);
    }
}