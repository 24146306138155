@import "./inventory.scss";

#universities {
    @extend #inventory;

    @include mq($xls) {
        padding: 15px;
    }

    .pow-text {
        letter-spacing: 0.25em;
    }

    .impact-text {
        text-align: center;
    }

    .cards {
        display: flex;
        gap: pxToVw(30);
        justify-content: center;
        flex-wrap: wrap;

        @include mq($xls) {
            gap: 15px;
        }
    }

    .single-uni-card {
        width: 12.25vw;
        height: 8vw;
        @include mq($lgmd){
            width: 8.7vw;
            height: 6vw;
        }
        @include mq($lg) {
            width: 13.6875vw;
            height: 8.625vw;
        }

        @include mq($xls) {
            width: 40vw;
            height: 120px;
        }

        display: grid;
        place-items: center;
        border-radius: 21px;
        background: #fff;
        box-shadow: 6px 23px 22px rgba(14, 77, 164, 0.03);

        img {
            width: 8.75vw;
            height: auto;
            @include mq($lgmd) {
                width: 6vw;
            }
            @include mq($xlg){
                width: 7.5vw;
            }
            @include mq($md) {
                width: 7.5vw;
            }

            @include mq($xls) {
                width: 120px;
            }
        }
    }
}